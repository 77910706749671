<template>
  <div class="preview dark" v-loading="loading" element-loading-text="加载中..." :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in project.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame">
          <div class="line" style="padding:24px 0 16px">
            <div class="right">
              <el-button type="primary" @click="dataSourceCreate()" v-if="addAuth">新增数据</el-button>
            </div>
          </div>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column min-width="5%" />
            <el-table-column label="名称" prop="name" min-width="20%"></el-table-column>
            <el-table-column label="类型" prop="typeName" min-width="10%" />
            <el-table-column label="用户名" prop="userName" min-width="15%" />
            <el-table-column label="链接地址" prop="url" min-width="40%" />
            <el-table-column label="操作" min-width="10%">
              <template #default="scope">
                <el-button link size="small" @click.prevent="dataSourceEdit(scope.$index, tableData)" v-if="editAuth">
                  编辑
                </el-button>
                <el-button link size="small" @click.prevent="dataSourceDel(scope.$index, tableData)" v-if="delAuth">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <empty v-if="tableData.length == 0" info="数据库"
               style="height:70vh"></empty> -->
          <div class="line pd-10">
            <page :total="conf.total" v-if="tableData.length" @onChange="onChange"></page>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <window windowId="edit-data-source" :shadow="true" class="dark" :title="dataSource.title" @windowHide="dataSourceHide"
      v-show="dataSource.isShow">
      <template #body>
        <div class="line pd-20">
          <el-form ref="form" :model="dataSource.info" :rules="rules">
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item prop="name">
                  <div class="insert-h">
                    <div class="attr">名称</div>
                    <div class="val">
                      <el-input v-model="dataSource.info.name" placeholder="请输入名称" />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="userName">
                  <div class="insert-h">
                    <div class="attr">用户名</div>
                    <div class="val">
                      <el-input v-model="dataSource.info.userName" placeholder="请输入用户名" />
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="2"></el-col>
              <el-col :span="11">
                <el-form-item prop="typeName">
                  <div class="insert-h">
                    <div class="attr">类型</div>
                    <div class="val">
                      <el-select v-model="dataSource.info.typeName" placeholder="请选择数据库类型" class="line"
                        @change="selectType">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                      <!-- <el-input v-model="dataSource.info.type"
                                  placeholder="请输入轨迹名称" /> -->
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="password">
                  <div class="insert-h">
                    <div class="attr">密码</div>
                    <div class="val">
                      <el-input v-model="dataSource.info.password" placeholder="请输入密码" />
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item prop="ip">
                  <div class="insert-h">
                    <div class="attr">IP地址</div>
                    <div class="val">
                      <el-input v-model="dataSource.info.ip" placeholder="请输入ip地址" />
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="2"></el-col>
              <el-col :span="11">
                <div class="insert-h">
                  <div class="attr">端口号</div>
                  <div class="val">
                    <el-input v-model="dataSource.info.port" placeholder="请输入端口号" />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="11" v-if="dataSource.info.type != 7">
                <div class="insert-h">
                  <div class="attr">数据库名称</div>
                  <div class="val">
                    <el-input v-model="dataSource.info.dbName" placeholder="请输入数据库名称" />
                  </div>
                </div>
              </el-col>
              <el-col :span="2"></el-col>
              <el-col :span="11" v-if="dataSource.info.type == 1">
                <div class="insert-h half">
                  <div class="attr">时区</div>
                  <div class="val">
                    <el-select v-model="dataSource.info.timeName" placeholder="请选择数据库时区" class="line"
                      @change="selectTime">
                      <el-option v-for="item in timeList" :key="item.id" :label="item.alias" :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24" v-if="dataSource.info.type == 1">
              <el-col :span="11">
                <div class="insert-h">
                  <div class="attr">SSL</div>
                  <div class="val">
                    <el-switch v-model="dataSource.info.ssl" inline-prompt active-text="是" inactive-text="否"
                      style="margin: 4px 0" />
                  </div>
                </div>
              </el-col>
              <el-col :span="2"></el-col>
              <el-col :span="11">
                <div class="insert-h">
                  <div class="attr">批量操作</div>
                  <div class="val">
                    <el-switch v-model="dataSource.info.multi" inline-prompt active-text="是" inactive-text="否"
                      style="margin: 4px 0" />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24" v-if="dataSource.info.type == 1">
                <div class="insert-h">
                  <div class="attr">字符集</div>
                  <div class="val">
                    <el-radio-group v-model="dataSource.info.characterType">
                      <el-radio :label="1">utf-8</el-radio>
                      <el-radio :label="0">其他</el-radio>
                    </el-radio-group>
                    <el-input v-model="dataSource.info.character" placeholder="字符集" style="width:20%;margin-left: 5%;" />
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <div class="insert-h">
                    <div class="attr">其他参数</div>
                    <div class="val">
                      <el-row :gutter="24">
                        <el-col :span="18">
                          <div class="insert-custom-line" v-for="(item, index) in params">
                            <el-input placeholder="请输入自定义属性" type="text" v-model="item.key"/>
                            <span>:</span>
                            <el-input placeholder="请输入自定义值" type="text" v-model="item.value"/>
                            <el-button class="icomoon-bin" @click="delParams(index)"></el-button>
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <el-button class="dark-button right"  @click="addParams(index)"><i class="icomoon-plus"></i>新增
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
             
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item prop="description">
                  <div class="insert-h">
                    <div class="attr">描述(非必填)</div>
                    <div class="val">
                      <el-input v-model="dataSource.info.description" placeholder="请输入描述" type="textarea" :rows="4" />
                    </div>
                  </div>
                </el-form-item>
                <div class="insert-h" v-if="dataSource.isEdit">
                  <div class="val">
                    <el-button type="primary" @click="isConnection()">连接测试</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form>

        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="dataSourceHide()">取消</el-button>
          <el-button type="primary" @click="dataSourceSave()">保存</el-button>
        </div>
      </template>
    </window>
  </div>
</template>

<script>
import Window from 'components/common/forms/Window';
import Page from 'components/common/forms/Page';
import Empty from 'components/common/forms/Empty';
import { prjBtnStatus, getSpaceOrPrjDetails, jumpRoute } from 'common/authBtn';
import _ from 'underscore';
import { validateSqlUrl } from 'common/validate';
export default {
  name: 'DataSource',
  components: {
    Window,
    Page,
    Empty
  },
  data () {
    return {
      loading: true,
      rules: {
        name: [{ required: true, message: '数据库名称不能为空', trigger: 'blur' }],
        typeName: [{ required: true, message: '请选择数据库类型', trigger: 'blur' }],
        userName: [{ required: true, message: '数据库用户名不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '数据库密码不能为空', trigger: 'blur' }],
        url: [{ required: true, message: '数据库连接地址不能为空', trigger: 'blur' }, { validator: validateSqlUrl, message: '请输入正确url', trigger: 'blur' }],
      },
      project: {},
      tableData: [],
      multipleSelection: [],
      dataSource: {
        isShow: false,
        isEdit: false,
        title: "",
        info: {
          name: '',
          type: 1,
          userName: '',
          password: "",
          url: '',
          description: '',
          typeName: 'MYSQL',
          dbName: '',
          characterType: 1,
          character: '',
          ip: '',
          port: '',
          multi: true,
          ssl: true,
          timeName: '世界时间',
          timeType: 1,
        }
      },
      addAuth: false,
      editAuth: false,
      delAuth: false,
      conf: {
        pageSize: 10,
        pageNum: 1,
        total: 10
      },
      typeList: [{ id: 1, name: 'MySQL' }, { id: 2, name: 'SQL Server' }, {id: 3, name: 'MariaDB'}, {id: 4, name: 'PostgreSQL'}, {id: 5, name: 'Oracle'}, {id: 6, name: 'KingbaseDB'},
      {id: 7, name: 'DM8'},{id: 8, name: 'openGauss'}],
      timeList: [{ id: 1, name: 'UTC', alias: '世界时间' }, { id: 2, name: 'Asia/Shanghai', alias: '上海' }, { id: 3, name: 'GMT%2B', alias: '北京' }],
      params: [{key:'', value: ''}]
    }
  },
  mounted () {
    this.getDetail();
  },
  methods: {
    getDetail () {
      let self = this;
      getSpaceOrPrjDetails(self.$store).then((res) => {
        self.loading = false;
        self.project = res;
        prjBtnStatus(res.id, self.$store, (result) => {
          self.addAuth = result.addAuth;
          self.editAuth = result.editAuth;
          self.delAuth = result.delAuth;
        });
        self.getDataFn();
      })
    },
    getDataFn () {
      let self = this;
      self.$api.sp.getAllDBs({
        prjId: self.project.id,
        pageNum: self.conf.pageNum,
        pageSize: self.conf.pageSize
      }).then((res) => {
        self.tableData = res.list;
        self.conf.total = res.total;
        _.each(self.tableData, (v) => {
          v.typeName = self.getTypeName(v.type);
        })
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    initForm () {
      this.$refs.form.clearValidate();
    },
    dataSourceCreate () {
      this.params = [{key:'', value: ''}];
      let ds = this.dataSource;
      ds.title = "创建数据源";
      ds.isEdit = false;
      ds.info = {
        name: '',
        type: 1,
        typeName: 'MYSQL',
        prjId: this.project.id,
        userName: '',
        password: "",
        url: '',
        description: '',
        dbName: '',
        characterType: 1,
        character: '',
        ip: '',
        port: '',
        multi: true,
        ssl: true,
        timeName: '世界时间',
        timeType: 1,
      }
      ds.isShow = true;
      this.initForm();
    },
    getTypeName (type) {
      let result = '';
      switch (parseInt(type)) {
        case 1:
          result = 'MySQL';
          break;
        case 2:
          result = 'SQL Server';
          break;
        case 3:
          result = 'mariaDB';
          break;
        case 4:
          result = 'PostgreSQL';
          break;
        case 5:
          result = 'Oracle';
          break;
        case 6:
          result = 'KingbaseDB';
          break;
        case 7:
          result = 'DM8';
          break;
        case 8:
          result = 'openGauss';
          break;
        default:
          break;
      }
      return result;
    },
    dataSourceEdit (i, list) {
      let ds = this.dataSource;
      ds.title = "编辑数据源";
      ds.isEdit = true;
      ds.info = this.deepClone(list[i]);
      if (ds.info.character == 'utf-8') {
        ds.info.characterType = 1;
        ds.info.character = '';
      } else
        ds.info.characterType = 0;

      this.params = ds.info.params ? JSON.parse(ds.info.params) : [];
      let obj = _.find(this.timeList, v => {
        return v.name == ds.info.timezone;
      })
      if (obj) {
        ds.info.timeName = obj.alias;
        ds.info.timeType = obj.id;
      }
      ds.isShow = true;
      this.initForm();
    },
    dataSourceDel (i, list) {
      let self = this;
      self
        .$confirm("此操作将永久删除该数据库记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.$api.sp.deleteDB({ id: list[i].id }).then((res) => {
            self.$message.success("删除成功!");
            self.getDataFn();
          });
        });
    },
    dataSourceHide () {
      this.dataSource.isShow = false;
    },
    dataSourceSave () {
      let self = this;
      self.constructUrl();
      self.dataSource.info.params = JSON.stringify(self.params);
      self.$refs.form.validate(valid => {
        if (valid) {
          if (!self.dataSource.isEdit) {
            self.$api.sp.addDB(self.dataSource.info).then((res) => {
              if (res) {
                self.$message.success('操作成功');
                self.getDataFn();
                self.dataSourceHide();
              }
            })
          } else {
            self.$api.sp.updateDB(self.dataSource.info).then((res) => {
              if (res) {
                self.$message.success('操作成功');
                self.getDataFn();
                self.dataSourceHide();
              }
            })
          }
        }
      })
    },
    constructUrl () {
      let self = this;
      let ds = self.dataSource.info;
      ds.url = '';
      switch(ds.type) {
        case 1:
          ds.url = 'jdbc:mysql://';
          break;
        case 2:
          ds.url = 'jdbc:sqlserver://';
          break;
        case 3:
          ds.url = 'jdbc:mariadb://';
          break;
        case 4:
          ds.url = 'jdbc:postgresql://';
          break;
        case 5:
          ds.url = 'jdbc:oracle:thin:@';
          break;
        case 6:
          ds.url = 'jdbc:kingbase8://';
          break;
        case 7:
          ds.url = 'jdbc:dm://';
          break;
        case 8:
          ds.url = 'jdbc:opengauss://';
          break;
        default:
          break;  
      }
      if(ds.type == 1) {
        ds.url = ds.url + ds.ip + ':' + ds.port + '/' + ds.dbName + '?' + 'serverTimezone=' + ds.timezone + '&' + 'useSSL=' + ds.ssl;
        ds.url += '&allowMultiQueries=' + ds.multi;
        if (ds.characterType == 1) {
          ds.url += '&characterEncoding=utf-8';
        } else {
          ds.url += '&characterEncoding=' + ds.character;
        }
      }else if(ds.type == 2){
        ds.url = ds.url + ds.ip + ':' + ds.port + ';database=' + ds.dbName
      }else if(ds.type == 3 || ds.type == 4 || ds.type == 6 || ds.type == 8){
        ds.url = ds.url + ds.ip + ':' + ds.port + '/' + ds.dbName
      }else if(ds.type == 5){
        ds.url = ds.url + ds.ip + ':' + ds.port + ':' + ds.dbName
      }else if(ds.type == 7){
        ds.url = ds.url + ds.ip + ':' + ds.port
      }

      if(self.params.length > 0) {
        this.params.forEach(v => {
          if(v.key && v.value) {
            if(ds.type == 1 || ds.type == 3 || ds.type == 4) {
              ds.url += '&'+v.key+'='+v.value;
            }else if(ds.type == 2){
              ds.url += ';'+v.key+'='+v.value;
            }else if(ds.type == 5) {
              ds.url += ':'+v.key+'='+v.value;
            }
          }
        })
      }
    },
    selectType (val) {
      this.dataSource.info.type = val;
      this.dataSource.info.typeName = this.getTypeName(val);
    },
    selectTime (val) {
      let obj = _.find(this.timeList, v => {
        return v.id == val;
      })
      if (obj) {
        this.dataSource.info.timeType = val;
        this.dataSource.info.timezone = obj.name;
      }
    },
    onChange (param) {
      this.conf.pageSize = param.pageSize;
      this.conf.pageNum = param.currentPage;
      this.getDataFn();
    },
    jumpSpace (n) {
      let self = this;
      if (self.project.id != n.id) {
        jumpRoute(self, n).then(() => {
          self.$router.push('/Main/SpaceDetail');
        })
      }
    },
    initData () {
      this.loading = true;
      this.tableData = [];
      this.conf = {
        pageSize: 10,
        pageNum: 1,
        total: 10
      };
    },
    isConnection () {
      let self = this;
      self.$api.sp.isConnection({
        url: self.dataSource.info.url,
        userName: self.dataSource.info.userName,
        password: self.dataSource.info.password,
        type: self.dataSource.info.type,
        databaseId: self.dataSource.info.id
      }).then((data) => {
        if (data) {
          self.$message.success('连接成功');
        } else {
          self.$message.error('连接失败');
        }
      })
    },
    delParams (index) {
      this.params.splice(index, 1);
    },
    addParams () {
      this.params.push({
        key: '',
        value: ''
      })
    },
  }
}
</script>

<style lang="scss" scoped></style>